<template>
    <div class="alert alert-warning alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> {{$t('general.reclamado')}}</h5>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group " :title="$t('general.fecha') + ' ' + $t('general.reclamacion') ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.fecha')}}</span>
                        <Calendar dateFormat="dd/mm/yy" style="width: 100%;" v-model="fecha_reclamacion_trabajo" />
                    </div>
                    
                </div>
                <div title="Reclamación pendiente de ser anulada">
                    <span v-if="trabajodatos.reclamacion_anulada_pendiente=='1'" class="badge bg-danger">Pendiente de anulación</span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ $t('general.precio')}} {{ $t('general.descontar')}}</span>
                    </div>
                    <input type="text" class="form-control" v-model="cantidad_reclamacion_trabajo"
                        :placeholder="$t('general.precio')">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <textarea class="form-control input-sm" v-model="texto_reclamacion_trabajo"
                        :placeholder="$t('general.motivo')" rows="1"></textarea>
                </div>
            </div>
            <div class="col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" @change="anularreclamacion()" v-model="anular" class="custom-control-input" :id="id_anular">
                    <label class="custom-control-label" :for="id_anular">{{ $t('general.anular')}} {{
                        $t('general.reclamacion')}}</label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Calendar from 'primevue/calendar'


export default ({
    props: ['trabajodatos','guardar'],
    setup() {
        return;
    },
    components: {
        Calendar
    },
    data() {
        return {
            reclamados: '',
            fecha_reclamacion_trabajo: '',
            texto_reclamacion_trabajo: '', 
            cantidad_reclamacion_trabajo: '',
            anular: false,
            pendiente: '',
            devuelta: '',
            id_anular:'',
        };
    },
    methods: {
        cargardatos() { 
            this.id_anular = this.$props.trabajodatos.idtrabajo_servicio + "anular";
            this.fecha_reclamacion_trabajo = new Date(this.$props.trabajodatos.fecha_reclamacion_trabajo).toLocaleDateString('es-ES');
            this.texto_reclamacion_trabajo = this.$props.trabajodatos.texto_reclamacion_trabajo;
            this.cantidad_reclamacion_trabajo = this.$props.trabajodatos.cantidad_reclamacion_trabajo;
            this.pendiente = this.$props.trabajodatos.reclamacion_anulada_pendiente;
            this.devuelta = this.$props.trabajodatos.reclamacion_anulada_devuelta;
            if (this.$props.trabajodatos.reclamacion_anulada_pendiente == 1) this.anular = true;


        },
        async anularreclamacion() {
            const api = new PwgsApi();
            let subidadatos = '';
            if (this.anular == true) {
                subidadatos = { reclamado_anular: '1' };
            }
            else {
                subidadatos = { reclamado_anular: '0' };
            }
            try {
                await api.put('trabajos/' + this.trabajodatos.idtrabajo_servicio + '/reclamar', subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
                this.anular = false;
            }
            this.$parent.$parent.$parent.togglerecargar();
            this.cargardatos();


        },  
        parseFecha(fecha) {
            if (typeof fecha === 'string') {
                // Si es una cadena de texto, asumimos que está en formato DD/MM/YYYY
                let partes = fecha.split('/');
                if (partes.length === 3) {
                    // Crear un nuevo objeto Date utilizando el formato correcto
                    return new Date(partes[2], partes[1] - 1, partes[0]); // Año, Mes, Día
                } else {
                    throw new Error("Formato de fecha no válido");
                }
            } else if (fecha instanceof Date) {
                // Si ya es un objeto Date, simplemente lo devolvemos
                return fecha;
            } else {
                throw new Error("Tipo de dato no soportado para la fecha");
            }
        }    
    },
    mounted() {
        this.cargardatos();
    },
    watch: {

        
        anular() {
            //this.anularreclamacion();
        },
        fecha_reclamacion_trabajo(newVal) {

            let auxfechareclama;
            if (newVal === null || newVal === '0000-00-00') {
                auxfechareclama = '';
            } else {
                let fecha = this.parseFecha(newVal);
                
                // Solo formatear si la fecha es válida
                let auxdiafin = fecha.toLocaleDateString("es-ES", { day: 'numeric' });
                let auxmesfin = fecha.toLocaleDateString("es-ES", { month: 'numeric' });
                
                // Asegurar el formato de dos dígitos para el mes y día
                if (auxmesfin < 10) { auxmesfin = "0" + auxmesfin }
                if (auxdiafin < 10) { auxdiafin = "0" + auxdiafin }

                auxfechareclama = auxdiafin + "/" + auxmesfin + "/" + fecha.toLocaleDateString("es-ES", { year: 'numeric' });
            }


            this.$emit('update:trabajodatos', { ...this.trabajodatos, fecha_reclamacion_trabajo: auxfechareclama });
        },
        texto_reclamacion_trabajo(newVal) {
            this.$emit('update:trabajodatos', { ...this.trabajodatos, texto_reclamacion_trabajo: newVal });
        },
        cantidad_reclamacion_trabajo(newVal) {
            this.$emit('update:trabajodatos', { ...this.trabajodatos, cantidad_reclamacion_trabajo: newVal });
        }

    }
})

</script>